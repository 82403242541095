import React, { createContext } from "react"
import { actualCountry } from "../utils/constants"

export const GlobalStateContext = createContext()
export const GlobalDispatchContext = createContext()

export const initialValue = props => {
  return { ...props }
}
export const loanValue = props => {
  return { ...props }
}
export const documentValue = props => {
  return { ...props }
}
export const isLoanNavigateValue = props => {
  return { ...props }
}

export const inputValues = props => {
  return { ...props }
}
export const dataReducer = (state, action) => {
  if (actualCountry === "mx") {
    switch (action.type) {
      case "homePage": {
        return { homePage: initialValue(action.data), ...state }
      }
      case "brokersPage": {
        return {
          brokersPage: initialValue(action.data),
          ...state,
        }
      }
      case "loanType": {
        return { loanType: loanValue(action.data), ...state }
      }
      case "ModalHomePage": {
        return { data: initialValue(action.data), ...state }
      }
      case "ReferHomePage": {
        return { data: initialValue(action.data), ...state }
      }
      case "idLoanNavigate": {
        return { data: isLoanNavigateValue(action.data), ...state }
      }
      case "home": {
        return {
          data: initialValue({
            minAmount: 100000,
            maxAmount: 10000000,
            minMonth: 6,
            maxMonth: 120,
          }),
          ...state,
        }
      }
      case "solution": {
        return {
          data: initialValue({
            minAmount: 100000,
            maxAmount: 10000000,
            minMonth: 6,
            maxMonth: 120,
          }),
          ...state,
        }
      }
      case "pymes_solution":
      case "mortgage": {
        return {
          data: initialValue({
            minAmount: 1000000,
            maxAmount: 50000000,
            minMonth: 6,
            maxMonth: 120,
          }),
          ...state,
        }
      }
      case "building": {
        return {
          data: initialValue({
            minAmount: 1000000,
            maxAmount: 50000000,
            minMonth: 6,
            maxMonth: 120,
          }),
          ...state,
        }
      }
      case "commercial": {
        return {
          data: initialValue({
            minAmount: 5000000,
            maxAmount: 25000000,
            minMonth: 6,
            maxMonth: 120,
          }),
          ...state,
        }
      }
      case "": {
        return {
          data: initialValue({
            minAmount: 250000,
            maxAmount: 25000000,
            minMonth: 6,
            maxMonth: 120,
          }),
          ...state,
        }
      }
      case "documentFile": {
        if (action.name === "title-Identification")
          return { identificationFile: documentValue(action), ...state }
        return { proofFile: documentValue(action), ...state }
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`)
      }
    }
  }

  if (actualCountry === "do") {
    switch (action.type) {
      case "homePage": {
        return {
          homePage: initialValue(action.data),
          ...state,
        }
      }
      case "brokersPage": {
        return {
          brokersPage: initialValue(action.data),
          ...state,
        }
      }
      case "loanType": {
        return {
          loanType: loanValue(action.data),
          ...state,
        }
      }
      case "ModalHomePage":
      case "ReferHomePage": {
        return {
          data: initialValue(action.data),
          ...state,
        }
      }
      case "idLoanNavigate": {
        return {
          data: isLoanNavigateValue(action.data),
          ...state,
        }
      }
      case "home": {
        return {
          data: initialValue({
            minAmount: 300000,
            maxAmount: 10000000,
            minMonth: 6,
            maxMonth: 120,
          }),
          ...state,
        }
      }
      case "solution": {
        return {
          data: initialValue({
            minAmount: 100000,
            maxAmount: 10000000,
            minMonth: 6,
            maxMonth: 120,
          }),
          ...state,
        }
      }
      case "pymes_solution":
      case "mortgage": {
        return {
          data: initialValue({
            minAmount: 1000000,
            maxAmount: 100000000,
            minMonth: 6,
            maxMonth: 120,
          }),
          ...state,
        }
      }
      case "building": {
        return {
          data: initialValue({
            minAmount: 1000000,
            maxAmount: 50000000,
            minMonth: 6,
            maxMonth: 120,
          }),
          ...state,
        }
      }
      case "commercial": {
        return {
          data: initialValue({
            minAmount: 5000000,
            maxAmount: 50000000,
            minMonth: 6,
            maxMonth: 120,
          }),
          ...state,
        }
      }
      case "": {
        return {
          data: initialValue({
            minAmount: 250000,
            maxAmount: 25000000,
            minMonth: 6,
            maxMonth: 120,
          }),
          ...state,
        }
      }
      case "documentFile": {
        if (action.name === "title-Identification")
          return {
            identificationFile: documentValue(action),
            ...state,
          }
        return {
          proofFile: documentValue(action),
          ...state,
        }
      }
      case "inputValues": {
        return { data: inputValues(action.data), ...state }
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`)
      }
    }
  }
}

export const GlobalDataProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(dataReducer, initialValue)
  const [stateIdLoan, dispatchIdLoan] = React.useReducer(
    dataReducer,
    isLoanNavigateValue
  )
  const [stateDocument, dispatchDocument] = React.useReducer(
    dataReducer,
    documentValue
  )
  const [stateLoan, dispatchLoan] = React.useReducer(dataReducer, loanValue)
  const [stateInputValue, dispatchInputValue] = React.useReducer(
    dataReducer,
    inputValues
  )
  return (
    <GlobalStateContext.Provider
      value={state}
      documentValue={stateDocument}
      idLoanNavigation={stateIdLoan}
      loanValue={stateLoan}
      inputValue={stateInputValue}
    >
      <GlobalDispatchContext.Provider
        value={dispatch}
        documentValue={dispatchDocument}
        idLoanNavigation={dispatchIdLoan}
        loanValue={dispatchLoan}
        inputValue={dispatchInputValue}
      >
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export const useDataState = () => {
  const context = React.useContext(GlobalStateContext)
  if (context === undefined) {
    throw new Error("useCountState must be used within a CountProvider")
  }
  return context
}

export const useDataDispatch = () => {
  const context = React.useContext(GlobalDispatchContext)
  if (context === undefined) {
    throw new Error("useCountDispatch must be used within a CountProvider")
  }
  return context
}
