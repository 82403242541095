export const DESKTOP_SCREEN_WIDTH = 1025
export const DOCUMENT_GLOBAL = typeof document !== "undefined"
export const isSSR = typeof window === "undefined"
export const MOBILE_SCREEN_WIDTH = 426
export const MOBILE_XS_SCREEN_WIDTH = 321
export const TABLET_SCREEN_WIDTH = 768
export const WINDOW_GLOBAL = typeof window !== "undefined" && window
export const actualCountry = process.env.GATSBY_SITE_VERSION
export const DOCUMENT_TYPES = {
  CURP: 5,
  RFC: 17,
}
export const CONTACT_TYPES = {
  EMAIL: 1,
  CELL: 2,
}
export const COUNTRIES = {
  MX: 2,
  DO: 1,
}
export const ADDRESS_TYPES = {
  HOUSE: 3,
  WORK: 4,
}
export const MARITAL_STATUSES = {
  SINGLE: 1,
  MARRIED: 2,
}

export const ERRORS = {
  101: "No posee la posición para hacer este cambio de estado.",
  102: "La solicitud no cumple con las condiciones para cambiar de estado.",
  103: "La solicitud no tiene los documentos necesarios para cambiar de estado.",
  201: "El nombre ya está registrado.",
  202: "El código ya está registrado.",
  203: "El manager ya está asignado a otra franquicia.",
  204: "Campo de email requerido.",
  303: "El correo suministrado ya está registrado.",
  401: "El cliente no tiene una dirección válida.",
  402: "El cliente no tiene correo y/o número de celular.",
  403: "El cliente no tiene los datos suficientes.",
  404: "El cliente ya tiene un flujo en progreso.",
  501: "Unykoo ID inválido en la petición.",
  502: "Inicialización inválida.",
  503: "Formulario inválido.",
  504: "Número de NIP inválido.",
  505: "Círculo de crédito inválido.",
  506: "Documentos SIC inválidos.",
  601: "La solicitud necesita tener un interés para calcular el rating.",
  701: "Persona registrada, número de documento duplicado.",
  702: "No tiene este cliente asignado.",
  703: "Persona no editable",
  711: "Persona moral registrada, número de documento duplicado.",
  712: "No tiene este cliente asignado.",
  713: "Persona moral no editable.",
  801: "Empleado registrado, código de empleado duplicado.",
  802: "El correo suministrado ya está registrado.",
}

export const getClientSource = () => {
  const referrer = DOCUMENT_GLOBAL && document.referrer
  if (WINDOW_GLOBAL && window.location.pathname === "/") return "website"
  if (referrer.includes("facebook.com")) return "fb"
  if (referrer.includes("instagram.com")) return "ig"
  if (
    WINDOW_GLOBAL &&
    window.location.pathname === "/feria-de-aprobacion-nacional"
  )
    return "fair"

  if (localStorage.getItem("gclid") !== "null") {
    return "internet-advertisement"
  }
  if (referrer.includes("google.com")) return "google"
  return null
}

export const getDeviceType = () => {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet"
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile"
  }
  return "desktop"
}

const getDateValuesFromTimestamp = timestamp => {
  const date = new Date(timestamp)

  return [
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ]
}

export const timestampToDateString = val => {
  if (!val) return ""
  const [year, month, day] = getDateValuesFromTimestamp(val)

  return `${day}/${month}/${year}`
}

export const ScrollSmooth = id => {
  const element = DOCUMENT_GLOBAL && document.getElementById(id)
  return element.scrollIntoView({ behavior: "smooth" })
}

export const percentageToDecimal = value => value / 100
