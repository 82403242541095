// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-adjuntar-documentos-jsx": () => import("./../../../src/pages/adjuntar-documentos.jsx" /* webpackChunkName: "component---src-pages-adjuntar-documentos-jsx" */),
  "component---src-pages-agentes-jsx": () => import("./../../../src/pages/agentes.jsx" /* webpackChunkName: "component---src-pages-agentes-jsx" */),
  "component---src-pages-credito-comercial-jsx": () => import("./../../../src/pages/credito-comercial.jsx" /* webpackChunkName: "component---src-pages-credito-comercial-jsx" */),
  "component---src-pages-credito-construccion-jsx": () => import("./../../../src/pages/credito-construccion.jsx" /* webpackChunkName: "component---src-pages-credito-construccion-jsx" */),
  "component---src-pages-credito-mejora-tu-cuota-jsx": () => import("./../../../src/pages/credito-mejora-tu-cuota.jsx" /* webpackChunkName: "component---src-pages-credito-mejora-tu-cuota-jsx" */),
  "component---src-pages-credito-negocio-jsx": () => import("./../../../src/pages/credito-negocio.jsx" /* webpackChunkName: "component---src-pages-credito-negocio-jsx" */),
  "component---src-pages-credito-solucion-jsx": () => import("./../../../src/pages/credito-solucion.jsx" /* webpackChunkName: "component---src-pages-credito-solucion-jsx" */),
  "component---src-pages-evaluacion-de-credito-jsx": () => import("./../../../src/pages/evaluacion-de-credito.jsx" /* webpackChunkName: "component---src-pages-evaluacion-de-credito-jsx" */),
  "component---src-pages-feria-de-aprobacion-nacional-jsx": () => import("./../../../src/pages/feria-de-aprobacion-nacional.jsx" /* webpackChunkName: "component---src-pages-feria-de-aprobacion-nacional-jsx" */),
  "component---src-pages-gracias-agente-jsx": () => import("./../../../src/pages/gracias-agente.jsx" /* webpackChunkName: "component---src-pages-gracias-agente-jsx" */),
  "component---src-pages-gracias-jsx": () => import("./../../../src/pages/gracias.jsx" /* webpackChunkName: "component---src-pages-gracias-jsx" */),
  "component---src-pages-gracias-onboarding-jsx": () => import("./../../../src/pages/gracias-onboarding.jsx" /* webpackChunkName: "component---src-pages-gracias-onboarding-jsx" */),
  "component---src-pages-gracias-referidor-jsx": () => import("./../../../src/pages/gracias-referidor.jsx" /* webpackChunkName: "component---src-pages-gracias-referidor-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-persona-fisica-jsx": () => import("./../../../src/pages/persona-fisica.jsx" /* webpackChunkName: "component---src-pages-persona-fisica-jsx" */),
  "component---src-pages-persona-moral-jsx": () => import("./../../../src/pages/persona-moral.jsx" /* webpackChunkName: "component---src-pages-persona-moral-jsx" */),
  "component---src-pages-plan-de-pago-jsx": () => import("./../../../src/pages/plan-de-pago.jsx" /* webpackChunkName: "component---src-pages-plan-de-pago-jsx" */),
  "component---src-pages-politica-cookies-jsx": () => import("./../../../src/pages/politica-cookies.jsx" /* webpackChunkName: "component---src-pages-politica-cookies-jsx" */),
  "component---src-pages-politica-privacidad-jsx": () => import("./../../../src/pages/politica-privacidad.jsx" /* webpackChunkName: "component---src-pages-politica-privacidad-jsx" */),
  "component---src-pages-productos-jsx": () => import("./../../../src/pages/productos.jsx" /* webpackChunkName: "component---src-pages-productos-jsx" */),
  "component---src-pages-quienes-somos-jsx": () => import("./../../../src/pages/quienes-somos.jsx" /* webpackChunkName: "component---src-pages-quienes-somos-jsx" */),
  "component---src-pages-refiere-y-gana-jsx": () => import("./../../../src/pages/refiere-y-gana.jsx" /* webpackChunkName: "component---src-pages-refiere-y-gana-jsx" */),
  "component---src-pages-super-prestamos-digitales-jsx": () => import("./../../../src/pages/super-prestamos-digitales.jsx" /* webpackChunkName: "component---src-pages-super-prestamos-digitales-jsx" */),
  "component---src-pages-terminos-y-condiciones-jsx": () => import("./../../../src/pages/terminos-y-condiciones.jsx" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-jsx" */),
  "component---src-pages-tipo-de-prestamo-jsx": () => import("./../../../src/pages/tipo-de-prestamo.jsx" /* webpackChunkName: "component---src-pages-tipo-de-prestamo-jsx" */)
}

